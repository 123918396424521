import React, { useState } from 'react';
import { Alert, Button, Flex, Input, List, Space } from 'antd';
import { CloseCircleOutlined, PlusOutlined } from '@ant-design/icons';
import './UrlWhiteList.css';

interface UrlWhiteListInputProps {
  id?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
}

export const UrlWhiteListInput: React.FC<UrlWhiteListInputProps> = (props) => {
  const { id, value = [], onChange } = props;
  const [inputText, setInputText] = useState<string>('');
  const [inputError, setInputError] = useState<string>('');

  const handleRemove = (url: string) => {
    onChange && onChange(value.filter(v => v !== url));
  }

  const handleAdd = () => {
    setInputError('');
    if (!inputText) return;

    try {
      const parsedUrl = new URL(inputText);
      onChange && onChange([...value, parsedUrl.origin]);
      setInputText('');
    } catch (error) {
      setInputError('Please enter a valid domain name.');
    }
  }

  return (
    <span id={id}>
      <List
        size="small"
        className='UrlWhiteListWrapper'
        header={
          <Space direction='vertical' style={{ width: '100%' }}>
            <Flex style={{ height: 32 }} gap={5}>
              <Input value={inputText} onChange={(e) => setInputText(e.target.value)} placeholder='http(s)://hostname' />
              <Button onClick={() => handleAdd()} icon={<PlusOutlined />} />
            </Flex>
            {inputError && <Alert type='error' message={inputError} closable afterClose={() => setInputError('')} />}
          </Space>
        }
        dataSource={value}
        renderItem={(item, index) => (
          <List.Item
            actions={[<Button onClick={() => handleRemove(item)} key={`white-list-${index}`} size='small' type='text' icon={<CloseCircleOutlined />}></Button>]}
          >
            {item}
          </List.Item>
        )}
      />
    </span>
  );
};