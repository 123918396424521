import React, { useEffect, useState } from 'react';
import { Avatar, Button, Card, Col, Drawer, List, Row, Skeleton, Space, Typography } from 'antd';
import { SysLogDTO, getSysLogContent, getSysLogList } from '../../services/LogService';
import { FileTextOutlined } from '@ant-design/icons';
import { KAPTAIN_URL } from '../../services/config';

type ProgramLogProps = {};
export const ProgramLog: React.FC<ProgramLogProps> = () => {
  const [logs, setLogs] = useState<SysLogDTO>();
  const [logViewOpen, setLogViewOpen] = useState<boolean>(false);
  const [logContent, setLogContent] = useState<string>();

  useEffect(() => {
    getSysLogList().then(logs => setLogs(logs));
  }, []);

  const viewLog = async (type: keyof SysLogDTO, filename: string) => {
    setLogViewOpen(true);
    const content = await getSysLogContent(type, filename);
    setLogContent(content);
  }

  const renderLogFileItem = (type: keyof SysLogDTO, filename: string, index: number) => {
    return (
      <List.Item
        actions={[
          <Button key={type + index} type='link' onClick={() => viewLog(type, filename)}>view</Button>,
          <Button key={type + index} type='link' target='_blank' href={`${KAPTAIN_URL}/api/log/sys/${type}/${filename}/download`}>download</Button>
        ]}
      >
        <Space>
          <Avatar shape="square" icon={<FileTextOutlined />} />
          <Typography.Text>{filename}</Typography.Text>
        </Space>
      </List.Item>
    )
  }

  return (
    <React.Fragment>
      <Row gutter={24}>
        <Col span={12}>
          <Card title="System log">
            <List loading={!logs} dataSource={logs?.sys} renderItem={(filename, index) => renderLogFileItem("sys", filename, index)} />
          </Card>
        </Col>
        <Col span={12}>
          <Card title="App log">
            <List loading={!logs} dataSource={logs?.app} renderItem={(filename, index) => renderLogFileItem("app", filename, index)} />
          </Card>
        </Col>
      </Row>
      <Drawer title="View log" width={1024} placement="right" maskClosable onClose={() => { setLogViewOpen(false); setLogContent(undefined); }} open={logViewOpen}>
        {logContent === undefined ? <Skeleton active /> : <pre>{logContent}</pre>}
      </Drawer>
    </React.Fragment>
  )
};
