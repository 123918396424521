import React, { } from 'react';
import { Input } from 'antd';

interface HostnameInputProps {
  id?: string;
  value?: string;
  onChange?: (value: string) => void;
}

export const HostnameInput: React.FC<HostnameInputProps> = (props) => {
  const { id, value = '', onChange } = props;

  const handleBlur = () => {
    try {
      const parsedUrl = new URL(value);
      onChange && onChange(parsedUrl.hostname);
    } catch (e) {
      onChange && onChange(value);
    }
  }

  const handleInput = (text: string) => {
    onChange && onChange(text);
  }

  return (
    <span id={id}>
      <Input value={value} onBlur={() => handleBlur()} onChange={(e) => handleInput(e.target.value)} placeholder='hostname only' />
    </span>
  );
};