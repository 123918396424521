import axios from 'axios';
import { Granule } from '../types/Granule';
import { formatToLocale, stringToUTC } from '../utils/dateUtils';
import { KAPTAIN_URL } from './config';

export const getGranules = async (kapsuleId: string): Promise<Granule[]> => {
  const kapsule = await axios.get<any>(`${KAPTAIN_URL}/api/kapsule/${kapsuleId}`);
  const {database = 'kaptaindb', tableName = 'kaptaindb'} = kapsule.data;
  const response = await axios.get<any[]>(`${KAPTAIN_URL}/api/kapsule/${kapsuleId}/sql/read/internal?sqlType=${database}&tableName=${tableName}`);
  return response.data.map((item) => {
    return {
      ...item,
      createdAt: formatToLocale(stringToUTC(item.createdAt), 'yyyy-MM-dd HH:mm:ss'),
      updatedAt: formatToLocale(stringToUTC(item.updatedAt), 'yyyy-MM-dd HH:mm:ss'),
    };
  });
};

export const deleteGranules = async (granuleIds: string[], kapsuleId: string): Promise<void> => {
  const kapsule = await axios.get<any>(`${KAPTAIN_URL}/api/kapsule/${kapsuleId}`);
  const {database = 'kaptaindb', tableName = 'kaptaindb'} = kapsule.data;
  await axios.delete(`${KAPTAIN_URL}/api/kapsule/sql/granules`, {
    data: { granuleIds, sqlType: database, tableName: tableName }
  });
};

export const importDataWithQVD = async (kapsuleId: string, formData: FormData): Promise<number> => {
  return new Promise(async (resolve, reject) => {
    axios.post<{ rows: number; }>(`${KAPTAIN_URL}/api/kapsule/import/qvd/data?kapsuleId=${kapsuleId}`, formData).then(res => {
      resolve(res.data.rows);
    }).catch(err => {
      reject(err.data);
    });
  })
};
