import {
  Button,
  Form,
  Input,
  Modal,
  Space,
  Spin,
  Table,
  Select,
  Tag,
  Checkbox,
  theme,
  App,
} from 'antd';
import Search from 'antd/lib/input/Search';
import { DeleteOutlined, SettingOutlined } from '@ant-design/icons';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Group } from '../../types/Group';
import { FORM_LAYOUT_8_16, FORM_VALIDATE_MESSAGES } from '../../types/config';
import { Kapsule } from '../../types/Kapsule';
import {
  createGroup,
  deleteGroup,
  getGroupList,
  updateGroup,
} from '../../services/GroupService';
import { getKapsules } from '../../services/KapsuleService';
import { getUserList } from '../../services/UserService';
import { User } from '../../types/User';
import { GroupAuthority } from '../../enums/GroupAuthority';
import { Role } from '../../enums/Role';
import _ from 'lodash';
import { AgentContext } from '../../App';
const { Option } = Select;
const { useToken } = theme;

type GroupTableProps = {};

type GroupOperation = 'add' | 'update';

const groupListSearchTextFilter = (
  record: Group,
  searchText: string
): boolean => {
  if (searchText === '') return true;
  return record.groupname.toLowerCase().includes(searchText);
};

export const GroupTable: FunctionComponent<GroupTableProps> = () => {
  const { token } = useToken();
  const { modal } = App.useApp();
  const [searchText, setSearchText] = useState<string>('');
  const [groupList, setGroupList] = useState<Group[]>();
  const [kapsules, setKapsules] = useState<Kapsule[]>();
  const [userList, setUserList] = useState<User[]>();
  const [modalOperation, setModalOperation] = useState<GroupOperation>('add');
  const [form] = Form.useForm<{
    groupId?: string;
    groupname: string;
    kapsules: Kapsule[];
    users: User[];
    authority: string[];
  }>();
  const [isGroupModalVisible, setIsGroupModalVisible] = useState(false);
  const agent = useContext(AgentContext);

  useEffect(() => {
    reloadGroupList();
    reloadKapsuleList();
    reloadUserList();
  }, []);

  const reloadGroupList = async () => {
    try {
      const groupList = await getGroupList();
      setGroupList(groupList);
    } catch (e) {
      setGroupList([]);
    }
  };

  const reloadKapsuleList = async () => {
    try {
      const kapsules = await getKapsules();
      setKapsules(kapsules);
    } catch (e) {
      setKapsules([]);
    }
  };

  const reloadUserList = async () => {
    try {
      const userList = await getUserList();
      setUserList(userList);
    } catch (e) {
      setUserList([]);
    }
  };

  const onSearch = (searchText: string) => {
    if (searchText) {
      setSearchText(searchText.toLowerCase());
    } else {
      setSearchText('');
    }
  };

  const handleGroupModalCancel = () => {
    setIsGroupModalVisible(false);
  };

  const handleGroupAuthorityChange = (checkvalues: any[]) => {
    if (checkvalues.includes(GroupAuthority.DELETE)) {
      form.setFieldsValue({
        authority: [GroupAuthority.READ, GroupAuthority.DELETE],
      });
    }
  };

  const handleGroupModalOk = async () => {
    try {
      await form.validateFields();
      const {
        groupId,
        groupname,
        kapsules,
        users,
        authority,
      } = form.getFieldsValue();

      // format kapsules select
      const kapsulesNew =
        kapsules &&
        kapsules.map((item: any) => {
          return {
            ...item,
            kapsulecode: item.value,
            kapsulename: item.label,
          };
        });
      // format users select
      const usersNew =
        users &&
        users.map((item: any) => {
          return {
            ...item,
            userid: item.value,
            username: item.label,
          };
        });

      if (modalOperation === 'add') {
        await createGroup({
          groupname,
          kapsules: kapsulesNew,
          users: usersNew,
          authority,
        });
      } else {
        if (groupId) {
          await updateGroup({
            groupId,
            groupname,
            kapsules: kapsulesNew,
            users: usersNew,
            authority,
          });
        }
      }
      setIsGroupModalVisible(false);
      reloadGroupList();
    } catch (e) {
      console.log('error', e);
    }
  };

  const columns = [
    { title: 'Group ID', dataIndex: '_id' },
    { title: 'Group Name', dataIndex: 'groupname' },
    {
      title: `${_.upperFirst(agent.template.kapsuleLabbel.pl)}`,
      dataIndex: '',
      key: 'k',
      render: (_text: string, record: Group) => (
        <Space wrap>
          {record.kapsules &&
            record.kapsules.map((item: any) => {
              return (
                <Tag key={`kapsule-${item.kapsulecode}`}>
                  {item.kapsulename}
                </Tag>
              );
            })}
        </Space>
      ),
    },
    {
      title: 'Users',
      dataIndex: '',
      key: 'u',
      render: (_text: string, record: Group) => (
        <Space wrap>
          {record.users &&
            record.users.map((item: any) => {
              return <Tag key={`kapsule-${item.userid}`}>{item.username}</Tag>;
            })}
        </Space>
      ),
    },
    {
      title: 'Authority',
      dataIndex: '',
      key: 'a',
      render: (_text: string, record: Group) => (
        <Space wrap>
          {record.authority?.map((e: any) => {
            if (e === GroupAuthority.READ)
              return (
                <Tag key={`authority-${e}`} color={token.colorSuccess}>
                  {e}
                </Tag>
              );
            if (e === GroupAuthority.DELETE)
              return (
                <Tag key={`authority-${e}`} color={token.colorError}>
                  {e}
                </Tag>
              );
          })}
        </Space>
      ),
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text: string, record: Group) => (
        <Space size="middle">
          <DeleteOutlined
            style={{ color: token.colorError }}
            onClick={() => {
              modal.confirm({
                title: 'Confirm',
                content: `Are you sure to delete group ${record.groupname}?`,
                okText: 'Yes',
                okType: 'primary',
                cancelText: 'Cancel',
                onOk: async () => {
                  await deleteGroup(record._id);
                  reloadGroupList();
                },
              });
            }}
          />
          <SettingOutlined
            style={{ color: token.colorPrimary }}
            onClick={() => {
              setModalOperation('update');
              form.setFieldsValue({
                groupId: record._id,
                groupname: record.groupname,
                authority: record.authority,
                kapsules: record.kapsules.map((item: Kapsule) => {
                  return {
                    ...item,
                    key: (item as any).kapsulecode,
                    value: (item as any).kapsulecode,
                    label: (item as any).kapsulename,
                  };
                }),
                users: record.users.map((item: any) => {
                  return {
                    ...item,
                    key: (item as any).userid,
                    value: (item as any).userid,
                    label: (item as any).username,
                  };
                }),
              });
              setIsGroupModalVisible(true);
            }}
          />
        </Space>
      ),
    },
  ];

  const authorityOptions = [
    { label: 'read', value: GroupAuthority.READ },
    { label: 'delete', value: GroupAuthority.DELETE },
  ];

  return groupList ? (
    <>
      <Table
        title={() => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <b>Groups</b>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setModalOperation('add');
                  setIsGroupModalVisible(true);
                }}
              >
                Create Group
              </Button>
              <Search
                placeholder="Search by group name"
                allowClear
                onSearch={onSearch}
                style={{ width: 200 }}
              />
            </Space>
          </div>
        )}
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={groupList.filter((item) =>
          groupListSearchTextFilter(item, searchText)
        )}
      />
      <Modal
        title={modalOperation === 'add' ? 'Add Group' : 'Update Group'}
        open={isGroupModalVisible}
        onOk={handleGroupModalOk}
        onCancel={handleGroupModalCancel}
      >
        <Form
          {...FORM_LAYOUT_8_16}
          name="add-group"
          form={form}
          validateMessages={FORM_VALIDATE_MESSAGES}
        >
          <Form.Item name="groupId" hidden label="Group ID">
            <Input />
          </Form.Item>
          <Form.Item
            name="authority"
            label="Permission"
            rules={[
              {
                required: true,
                message: 'Please select your Group Permission!',
              },
            ]}
            initialValue={[GroupAuthority.READ, GroupAuthority.DELETE]}
          >
            <Checkbox.Group
              options={authorityOptions}
              onChange={handleGroupAuthorityChange}
            />
          </Form.Item>
          <Form.Item
            name="groupname"
            label="Group Name"
            rules={[
              {
                required: true,
                message: 'Please input your group name!',
              },
            ]}
          >
            <Input placeholder="Input Group Name" />
          </Form.Item>
          <Form.Item name="kapsules" label={_.upperFirst(agent.template.kapsuleLabbel.pl)}>
            <Select mode="multiple" labelInValue placeholder={`Select ${_.upperFirst(agent.template.kapsuleLabbel.pl)}`}>
              {kapsules?.map((item: Kapsule) => {
                return (
                  <Option value={item._id} key={item._id}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="users" label="Users">
            <Select mode="multiple" labelInValue placeholder="Select Users">
              {userList?.map((item: User) => {
                if (item.role === Role.USER) {
                  return (
                    <Option value={item.userId} key={item.userId}>
                      {item.username}
                    </Option>
                  );
                }
              })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  ) : (
    <div
      style={{
        height: 312,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Spin />
    </div>
  );
};
