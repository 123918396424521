import { Button, Form, Input, Modal, Space, Spin, Table, Select, Checkbox, theme, App, Tag } from 'antd';
import Search from 'antd/lib/input/Search';
import { DeleteOutlined, LockOutlined, SettingOutlined, UnlockOutlined } from '@ant-design/icons';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { User } from '../../types/User';
import { createUser, deleteUser, getUserList, updateUser, getUserGroups, unlockUser } from '../../services/UserService';
import { FORM_LAYOUT_8_16, FORM_VALIDATE_MESSAGES } from '../../types/config';
import { Role } from '../../enums/Role';
import { getGroupList } from '../../services/GroupService';
import { Group } from '../../types/Group';
import _ from 'lodash';
import { AgentContext, UserContext } from '../../App';
const { Option } = Select;
const { useToken } = theme;

type UserTableProps = {};

type UserOperation = 'add' | 'update';

const userListSearchTextFilter = (record: User, searchText: string): boolean => {
  if (searchText === '') return true;
  return record.username.toLowerCase().includes(searchText);
};

export const UserTable: FunctionComponent<UserTableProps> = () => {
  const { token } = useToken();
  const { modal } = App.useApp();
  const agent = useContext(AgentContext);
  const [searchText, setSearchText] = useState<string>('');
  const [userList, setUserList] = useState<User[]>();
  const [groupList, setGroupList] = useState<Group[]>();
  const [roleSel, setRoleSel] = useState<string>();
  const user = useContext(UserContext);
  const [modalOperation, setModalOperation] = useState<UserOperation>('add');
  const [form] = Form.useForm<{
    password: string;
    username: string;
    userId?: string;
    role: Role;
    group: Group[];
    qlikId: string;
    isCreate: boolean;
  }>();
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isUserSelected, setIsUserSelected] = useState(false);

  useEffect(() => {
    reloadUserList();
    reloadGroupList();
  }, []);

  const reloadUserList = async () => {
    try {
      const userList = await getUserList();
      setUserList(userList);
    } catch (e) {
      setUserList([]);
    }
  };

  const reloadGroupList = async () => {
    try {
      const groupList = await getGroupList();
      setGroupList(groupList);
    } catch (e) {
      setGroupList([]);
    }
  };

  const onSearch = (searchText: string) => {
    if (searchText) {
      setSearchText(searchText.toLowerCase());
    } else {
      setSearchText('');
    }
  };

  const handleRoleChange = async (role: string) => {
    setRoleSel(role);
    // reset isCreate
    form.setFieldsValue({
      isCreate: true,
    });
    switch (role) {
      case Role.USER:
        try {
          const groupList = await getGroupList();
          setGroupList(groupList);
        } catch (e) {
          setGroupList([]);
        }
        setIsUserSelected(true);
        break;
      default:
        setIsUserSelected(false);
        break;
    }
  };

  const handleUserModalCancel = () => {
    setIsUserModalVisible(false);
  };

  const handleUserModalOk = async () => {
    try {
      await form.validateFields();
      const { userId, username, password, role, group, qlikId, isCreate } = form.getFieldsValue();

      // format group select
      let groupsNew = group === undefined ? [] : group;
      groupsNew = groupsNew.map((item: any) => {
        return {
          ...item,
          id: item.value,
          groupname: item.label,
        };
      });

      if (modalOperation === 'add') {
        await createUser({
          username,
          password,
          role,
          groups: groupsNew,
          qlikId,
          isCreate,
        });
      } else {
        if (userId) {
          await updateUser({
            userId,
            password: password === '' || !password ? undefined : password,
            role,
            groups: groupsNew,
            qlikId,
            isCreate,
          });
        }
      }
      setIsUserModalVisible(false);
      reloadUserList();
    } catch (e) { }
  };

  const columns = [
    { title: 'User ID', dataIndex: 'userId' },
    { title: 'User Name', dataIndex: 'username' },
    { title: 'Role', dataIndex: 'role' },
    {
      title: 'Status', dataIndex: 'isLocked', render: (isLocked: boolean) => {
        return !!isLocked ? <Tag icon={<LockOutlined />} color='warning'>Locked</Tag> : <Tag color='success'>Active</Tag>
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text: string, record: User) => (
        <Space size="middle">
          <DeleteOutlined
            style={{ color: token.colorError }}
            onClick={() => {
              modal.confirm({
                title: 'Confirm',
                content: `Are you sure to delete ${record.username}?`,
                okText: 'Yes',
                cancelText: 'Cancel',
                onOk: async () => {
                  await deleteUser(record.userId);
                  reloadUserList();
                },
              });
            }}
          />
          <SettingOutlined
            style={{ color: token.colorPrimary }}
            onClick={async () => {
              setModalOperation('update');
              form.setFieldsValue({
                userId: record.userId,
                username: record.username,
                password: '',
                role: record.role,
                qlikId: record.qlikId,
                isCreate: record.isCreate,
              });
              setRoleSel(record.role);
              if (record.role === Role.USER) {
                setIsUserSelected(true);
                // async function to get user groups
                const userGroups = await getUserGroups(record.userId);
                form.setFieldsValue({
                  group: userGroups.map((item: Group) => {
                    return {
                      ...item,
                      key: (item as any)._id,
                      value: (item as any)._id,
                      label: (item as any).groupname,
                    };
                  }),
                });
              } else if (record.role === Role.ADMIN) {
                setIsUserSelected(false);
              }
              setIsUserModalVisible(true);
            }}
          />
          {user?.username === 'admin' && !!record.isLocked && (
            <UnlockOutlined
              style={{ color: token.colorPrimary }}
              onClick={() => {
                modal.confirm({
                  title: 'Confirm',
                  content: `Are you sure to unlock ${record.username}?`,
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  onOk: async () => {
                    await unlockUser(record.userId);
                    reloadUserList();
                  },
                });
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return userList ? (
    <>
      <Table
        title={() => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <b>Users</b>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setModalOperation('add');
                  form.resetFields();
                  setIsUserSelected(false);
                  setIsUserModalVisible(true);
                }}
              >
                Create User
              </Button>
              <Search placeholder="Search by user name" allowClear onSearch={onSearch} style={{ width: 200 }} />
            </Space>
          </div>
        )}
        rowKey={(record) => record.userId}
        columns={columns}
        dataSource={userList.filter((item) => userListSearchTextFilter(item, searchText))}
      />
      <Modal
        title={modalOperation === 'add' ? 'Add User' : 'Update User'}
        open={isUserModalVisible}
        onOk={handleUserModalOk}
        onCancel={handleUserModalCancel}
      >
        <Form {...FORM_LAYOUT_8_16} name="add-user" form={form} validateMessages={FORM_VALIDATE_MESSAGES}>
          <Form.Item name="userId" hidden label="User ID">
            <Input />
          </Form.Item>
          <Form.Item
            name="username"
            label="User Name"
            rules={[
              {
                required: true,
                message: 'Please input your user name!',
              },
            ]}
          >
            <Input disabled={modalOperation !== 'add'} />
          </Form.Item>
          <Form.Item name="password" label="Password" rules={[{ required: modalOperation === 'add' }]}>
            <Input.Password />
          </Form.Item>
          <Form.Item name="role" label="Role" rules={[{ required: true }]}>
            <Select placeholder="Select User Role" onChange={(e: string) => handleRoleChange(e)}>
              <Option value={Role.ADMIN}>ADMIN</Option>
              <Option value={Role.USER}>USER</Option>
            </Select>
          </Form.Item>
          {isUserSelected ? (
            <Form.Item name="group" label="Group">
              <Select mode="multiple" labelInValue placeholder="Select User Group">
                {groupList?.map((item: Group) => {
                  return (
                    <Option value={item._id} key={item._id}>
                      {item.groupname}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          ) : null}
          <Form.Item name="qlikId" label="Qlik User ID" rules={[{ required: true }]}>
            <Input placeholder="Input User Qlik User ID" />
          </Form.Item>
          <Form.Item
            name="isCreate"
            label={`Can Create ${_.upperFirst(agent.template.kapsuleLabbel.sing)}`}
            valuePropName="checked"
            initialValue={true}
            rules={[{ required: true }]}
          >
            <Checkbox disabled={roleSel === Role.ADMIN} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  ) : (
    <div style={{ height: 312, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin />
    </div>
  );
};
