import { Typography } from 'antd';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { AgentContext } from '../../App';
import { fetchAPPVersion } from '../../services/AuthService';

const { Paragraph } = Typography;

type AboutProps = {};

export const About: FunctionComponent<AboutProps> = () => {
  const agent = useContext(AgentContext);
  const [appVersion, setAppVersion] = useState<string>('')

  useEffect(() => {
    getAPPVersion();
  }, []);

  const getAPPVersion = async () => {
    try {
      const version = await fetchAPPVersion();
      setAppVersion(version);
    } catch (e) {
      setAppVersion('unknown version');
    }
  }

  return (
    <Typography>
      <img className="sidebar-logo" src={agent.template.logoSrc} alt="logo" />
      <Paragraph>
        Version: {appVersion}
      </Paragraph>
    </Typography>
  );
};
