import { Radio, Space, Table, Button, Tooltip, theme, App } from 'antd';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import React, { FunctionComponent, useState } from 'react';
import { Granule } from '../../types/Granule';
import { deleteGranules } from '../../services/GranuleService';
import { ImportGranulesModal } from './ImportGranulesModal';
const { useToken } = theme;

type GranulesTableProps = {
  granules?: Granule[];
  kapsuleId: string;
  handleReloadGranules: () => void;
};

type GranulesTableFilter = 'ALL' | 'RELOADED' | 'NOT_RELOADED';

const filterOptions: Array<{ label: string; value: GranulesTableFilter }> = [
  { label: 'All', value: 'ALL' },
  { label: 'Reloaded', value: 'RELOADED' },
  { label: 'Not Reloaded', value: 'NOT_RELOADED' },
];

const granuleReloadedStatusFilter = (record: Granule, filter: GranulesTableFilter): boolean => {
  if (filter === 'ALL') return true;
  if (filter === 'RELOADED') return record.reloaded === true;
  if (filter === 'NOT_RELOADED') return record.reloaded !== true;
  return true;
};

const granuleSearchTextFilter = (record: Granule, searchText: string): boolean => {
  if (searchText === '') return true;
  return JSON.stringify(record).toLowerCase().includes(searchText);
};

export const GranulesTable: FunctionComponent<GranulesTableProps> = ({ granules, handleReloadGranules, kapsuleId }) => {
  const { token } = useToken();
  const { modal } = App.useApp();
  const [importOpen, setImportOpen] = useState<boolean>(false);

  const [filter, setFilter] = useState<GranulesTableFilter>('ALL');
  const [searchText, setSearchText] = useState<string>('');
  const [selectedKapsuleChildIds, setSelectedKapsuleChildIds] = useState<string[]>([]);
  if (!granules) return null;


  const columns: ColumnsType<Granule> = [
    {
      title: 'User', dataIndex: 'user',
      sorter: (a: Granule, b: Granule) => a.user.localeCompare(b.user)
    },
    {
      title: 'ID', dataIndex: '_id',
      sorter: (a: Granule, b: Granule) => a._id.localeCompare(b._id)
    },
    {
      title: 'Key Value', dataIndex: 'key',
      sorter: (a: Granule, b: Granule) => {
        let akey = a.key;
        let bkey = b.key;
        if (akey === null) akey = ''
        if (bkey === null) bkey = ''
        return akey.toString().localeCompare(bkey.toString())
      }
    },
    {
      title: 'Last Update', dataIndex: 'createdAt',
      sorter: (a: Granule, b: Granule) => {
        const aTime = new Date(a.createdAt).getTime();
        const bTime = new Date(b.createdAt).getTime();
        return aTime - bTime;
      }
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text: string, record: Granule) => (
        <Space size="middle">
          <Tooltip title="Delete Granule">
            <DeleteOutlined
              style={{ color: token.colorError }}
              onClick={() => {
                modal.confirm({
                  title: 'Confirm',
                  content: `Are you sure to delete ${record._id}?`,
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  onOk: async () => {
                    await deleteGranules([record._id], kapsuleId);
                    handleReloadGranules();
                  },
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const onSearch = (searchText: string) => {
    if (searchText) {
      setSearchText(searchText.toLowerCase());
    } else {
      setSearchText('');
    }
  };

  const handleFilterChange = (value?: GranulesTableFilter) => {
    if (value) {
      setFilter(value);
    }
  };

  const rowSelection = {
    onChange: (_selectedRowKeys: React.Key[], selectedRows: Granule[]) => {
      const selectedKeyIds = selectedRows.map((item) => item._id);
      setSelectedKapsuleChildIds(selectedKeyIds);
    }
  };

  return (
    <Table
      title={() => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <b>Granules</b>
          <Space>
            <Radio.Group
              options={filterOptions}
              onChange={({ target: { value } }: { target: { value?: GranulesTableFilter } }) =>
                handleFilterChange(value)
              }
              value={filter}
              optionType="button"
              buttonStyle="solid"
            />
            <Button
              disabled={selectedKapsuleChildIds.length === 0}
              danger
              onClick={() => {
                modal.confirm({
                  title: 'Confirm',
                  content: `Are you sure to delete selected Granules?`,
                  okText: 'Yes',
                  cancelText: 'Cancel',
                  onOk: async () => {
                    await deleteGranules(selectedKapsuleChildIds, kapsuleId);
                    setSelectedKapsuleChildIds([]);
                    handleReloadGranules();
                  },
                });
              }}
            >
              Delete Selection
            </Button>
            <Button
              icon={<CloudUploadOutlined />}
              onClick={() => { setImportOpen(true); }}
            >
              Import
            </Button>
            <Search placeholder="input search text" allowClear onSearch={onSearch} style={{ width: 200 }} />
          </Space>
          <ImportGranulesModal
            open={importOpen}
            kapsuleId={kapsuleId}
            handleSubmit={() => {
              setImportOpen(false);
              handleReloadGranules();
            }}
            handleCancel={() => setImportOpen(false)}
          />
        </div>
      )}
      rowKey={(record) => record._id}
      columns={columns}
      rowSelection={{ ...rowSelection }}
      dataSource={granules
        .filter((item) => granuleReloadedStatusFilter(item, filter))
        .filter((item) => granuleSearchTextFilter(item, searchText))}
      expandable={{
        expandedRowRender: (record) => {
          const text = JSON.stringify(record.data, undefined, 4);
          return (
            <pre>
              <code>{text}</code>
            </pre>
          );
        },
        rowExpandable: (record) => !!record.data,
      }}
    />
  );
};
