export const setFavicon = (href: string) => {
  const head = document.querySelector('head') as HTMLElement;
  const existingFavicon = document.querySelector('link[rel="icon"]');
  
  if (existingFavicon) {
    head.removeChild(existingFavicon);
  }
  
  const newFavicon = document.createElement('link');
  newFavicon.rel = 'icon';
  newFavicon.href = href;
  
  head.appendChild(newFavicon);
}
