import { AgentConfig } from ".";
import logo from "./assets/extendbi/extendbi.logo.png";
import favicon from "./assets/extendbi/extendbi.favicon.ico";

export const extendbi: AgentConfig = {
  theme: {
    colorPrimary: '#195BF1',
    leftSideMenuBgColor: '#002140',
    leftSideMenuTheme: 'dark',
  },
  template: {
    logoSrc: logo,
    faviconHerf: favicon,
    pageTitle: "Kaptain Admin Console",
    kaptaindbLabel: "Kaptain DB",
    kapsuleLabbel: {
      sing: "kapsule",
      pl: "kapsules"
    },
    kommentLabbel: "komment",
    kaptainLabbel: "Kaptain",
  },
  framework: {

  }
}