import { AgentConfig } from ".";
import logo from "./assets/qlik/qlik.logo.png";
import favicon from "./assets/qlik/qlik.favicon.ico";

export const qlik: AgentConfig = {
  theme: {
    colorPrimary: '#009845',
    leftSideMenuBgColor: '#FAFAFA',
    leftSideMenuTheme: 'light',
  },
  template: {
    logoSrc: logo,
    faviconHerf: favicon,
    pageTitle: "Commentary Admin Console",
    kaptaindbLabel: "Commentary DB",
    kapsuleLabbel: {
      sing: "data source",
      pl: "data source"
    },
    kommentLabbel: "Qlik Commentary",
    kaptainLabbel: "Commentary Server",
  },
  framework: {

  }
}