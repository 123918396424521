import { Avatar, Dropdown, Layout, Menu, MenuProps, Typography, theme } from 'antd';
import {
  TableOutlined,
  DatabaseOutlined,
  LogoutOutlined,
  UserOutlined,
  GroupOutlined,
  SettingOutlined,
  UnorderedListOutlined,
  KeyOutlined,
  FileSearchOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, useLocation, Navigate } from 'react-router-dom';
import './Home.css';
import { KapsulesTable } from '../../components/kapsulesTable/KapsulesTable';
import { Granules } from '../../components/granules/Granules';

import { AgentContext, UserContext } from '../../App';
import { Role } from '../../enums/Role';
import { UserTable } from '../../components/user/UserTable';
import { LogTable } from '../../components/log/LogTable';
import { GroupTable } from '../../components/group/GroupTable';
import { ApiKeyTable } from '../../components/apikey/ApiKeyTable';
import { ProgramLog } from '../../components/programLog/ProgramLog';

import { User } from '../../types/User';
import { SettingComp } from '../../components/setting/SettingComp';
import { getSettings } from '../../services/SettingCompService';
import _ from 'lodash';
import { About } from '../../components/about/About';

const { Header, Content, Sider } = Layout;
const { Title } = Typography;
const { useToken } = theme;

type HomeProps = {};

export const Home: FunctionComponent<HomeProps> = () => {
  const { token } = useToken();
  const agent = useContext(AgentContext);
  const user = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const [useApikey, setUseApikey] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    getServerSetting()
  }, []);

  /**
   * get server`s config.json
   */
  const getServerSetting = () => {
    //get data
    getSettings().then((res) => {
      setUseApikey(Boolean(res.settings.apiKey));
    }).catch(() => {
      setUseApikey(false);
    })
  }

  const logout = () => {
    window.localStorage.removeItem('token');
    navigate('/login');
  };

  const userData = localStorage.getItem('user') as string;
  const userInfo = (JSON.parse(userData) as unknown) as User;

  const menu: MenuProps['items'] = [
    {
      key: "username",
      label: (
        <div className="user-dropdown-heading">
          <Title level={5}>{userInfo?.username}</Title>
        </div>
      )
    }, {
      key: "logout",
      onClick: logout,
      label: (
        <div className="user-dropdown-item">
          <LogoutOutlined rev={undefined} />
          <span className="user-dropdown-text">Logout</span>
        </div>
      )
    }
  ];

  return (
    <Layout style={{ height: '100%' }}>
      <Sider
        style={{
          backgroundColor: agent.theme.leftSideMenuBgColor
        }}
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <div className="site-layout-background" style={{ height: 64 }}>
          <img className="sidebar-logo" src={agent.template.logoSrc} alt="logo" />
        </div>
        <Menu
          selectedKeys={[pathname]}
          defaultOpenKeys={[
            'data-management',
            'system-management'
          ]}
          theme={agent.theme.leftSideMenuTheme}
          style={{
            backgroundColor: agent.theme.leftSideMenuBgColor
          }}
          mode="inline"
          items={[
            {
              key: "data-management",
              label: "Data",
              icon: <DatabaseOutlined />,
              children: [
                {
                  key: "/data/kapsules",
                  icon: <TableOutlined />,
                  label: (
                    <Link to={`/data/kapsules`}>{_.upperFirst(agent.template.kapsuleLabbel.pl)}</Link>
                  )
                }, {
                  key: "/system/logs",
                  icon: <UnorderedListOutlined />,
                  label: (
                    <Link to={`/system/logs`}>Logs</Link>
                  )
                }
              ]
            }, {
              key: "system-management",
              label: "System",
              icon: <SettingOutlined />,
              disabled: !(user && user.role === Role.ADMIN),
              children: [
                {
                  key: "/system/users",
                  icon: <UserOutlined />,
                  label: (
                    <Link to={`/system/users`}>Users</Link>
                  )
                }, {
                  key: "/system/groups",
                  icon: <GroupOutlined />,
                  label: (
                    <Link to={`/system/groups`}>Groups</Link>
                  )
                }, {
                  key: "/data/apikey",
                  icon: <KeyOutlined />,
                  disabled: !(user && user.role === Role.ADMIN && useApikey),
                  label: (
                    <Link to={`/data/apikey`}>Api Key</Link>
                  )
                }, {
                  key: "/program/logs",
                  icon: <FileSearchOutlined />,
                  label: (
                    <Link to={`/program/logs`}>System Logs</Link>
                  )
                }, {
                  key: "/data/setting",
                  icon: <SettingOutlined />,
                  label: (
                    <Link to={`/data/setting`}>Settings</Link>
                  )
                }
              ]
            }, {
              key: "about-management",
              label: (
                <Link to={`/about`}>About</Link>
              ),
              icon: <InfoCircleOutlined />,
            }
          ]}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: '0 20px', textAlign: 'right' }}>
          <Dropdown menu={{ items: menu }} trigger={['click']}>
            <Avatar style={{ color: token.colorWhite, backgroundColor: token.colorPrimary, cursor: 'pointer' }}>
              {userInfo?.username}
            </Avatar>
          </Dropdown>
        </Header>
        <Content id='site-layout-scroll-content' style={{ margin: '16px', overflowY: 'auto' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <Routes>
              <Route index element={<Navigate to={'/data/kapsules'} />} />
              <Route path={`/data/kapsules`} element={<KapsulesTable />} />
              <Route path={`/data/kapsules/:kapsuleId`} element={<Granules />} />
              <Route path={`/system/logs`} element={<LogTable />} />
              {user && user.role === Role.ADMIN && (
                <React.Fragment>
                  <Route path={`/system/users`} element={<UserTable />} />
                  <Route path={`/system/groups`} element={<GroupTable />} />
                  <Route path={`/program/logs`} element={<ProgramLog />} />
                  <Route path={`/data/setting`} element={<SettingComp />} />
                  {useApikey && <Route path={`/data/apikey`} element={<ApiKeyTable />} />}
                </React.Fragment>
              )}
              <Route path={`/about`} element={<About />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
