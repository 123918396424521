import { extendbi } from './extendbi.config';
import { qlik } from './qlik.config';

export interface AgentConfig {
  theme: {
    // Brand color is one of the most direct visual elements to reflect the characteristics and communication of the product. After you have selected the brand color, we will automatically generate a complete color palette and assign it effective design semantics.
    colorPrimary: string;
    // left side menu background color.
    leftSideMenuBgColor: string;
    // left side menu theme.
    leftSideMenuTheme: 'light' | 'dark';
  }
  template: {
    // logo import address.
    logoSrc: string;
    // favicon import adress.
    faviconHerf: string;
    // title.
    pageTitle: string;
    // how to named kaptaindb in kinds of agents.
    kaptaindbLabel: string;
    // how to named kapsule.
    kapsuleLabbel: {
      // singular
      sing: string;
      // plural
      pl: string;
    }
    // how to named komment.
    kommentLabbel: string;
    // how to named kaptain.
    kaptainLabbel: string;
  }
  framework: {

  }
}

export type Agent = 'extendbi' | 'qlik';

const configMap: { [key in Agent]: AgentConfig } = {
  extendbi,
  qlik,
};

const agent: Agent = process.env.REACT_APP_AGENT as Agent || 'extendbi';

// eslint-disable-next-line no-restricted-syntax
export default configMap[agent];