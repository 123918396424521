import React, { useState } from 'react';
import { Input, Select, Space } from 'antd';

const { Option } = Select;

type TimeUnit = 's' | 'm' | 'h';

interface ExpiresTimeValue {
  number?: number;
  timeUnit?: TimeUnit;
}

interface ExpiresTimeInputProps {
  id?: string;
  value?: ExpiresTimeValue;
  onChange?: (value: ExpiresTimeValue) => void;
}

export const ExpiresTimeInput: React.FC<ExpiresTimeInputProps> = (props) => {
  const { id, value = {}, onChange } = props;
  const [number, setNumber] = useState(0);
  const [timeUnit, setTimeUnit] = useState<TimeUnit>('h');

  const triggerChange = (changedValue: { number?: number; timeUnit?: TimeUnit }) => {
    onChange?.({ number, timeUnit, ...value, ...changedValue });
  };

  const onNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newNumber = parseInt(e.target.value || '0', 10);
    if (Number.isNaN(number)) {
      return;
    }
    if (!('number' in value)) {
      setNumber(newNumber);
    }
    triggerChange({ number: newNumber });
  };

  const onTimeUnitChange = (newTimeUnit: TimeUnit) => {
    if (!('timeUnit' in value)) {
      setTimeUnit(newTimeUnit);
    }
    triggerChange({ timeUnit: newTimeUnit });
  };

  return (
    <span id={id}>
      <Space>
        <Input
          type="text"
          value={value.number || number}
          min={0}
          onChange={onNumberChange}
          style={{ width: 100 }}
        />
        <Select
          value={value.timeUnit || timeUnit}
          style={{ width: 100 }}
          onChange={onTimeUnitChange}
        >
          <Option value="s">Seconds</Option>
          <Option value="m">Minutes</Option>
          <Option value="h">Hours</Option>
        </Select>
      </Space>
    </span>
  );
};