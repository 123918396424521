import React, { Suspense, createContext, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { PrivateRoute } from './components/privateRoute/PrivateRoute';
import { Home } from './pages/home/Home';
import { Login } from './pages/login/Login';
import axios from 'axios';
import { User } from './types/User';
import { ConfigProvider, Spin, App as AntdApp } from 'antd';
import agent, { AgentConfig } from './agents';
import { setFavicon } from './utils/setFavicon';

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.setItem('token', '');
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
);

const { theme } = agent;

export const UserContext = createContext<User | undefined>(undefined);
export const AgentContext = createContext<AgentConfig>(agent);

function App() {
  const [user, setUser] = useState<User>();
  const location = useLocation();

  useEffect(() => {
    setFavicon(agent.template.faviconHerf);
    document.title = agent.template.pageTitle;
  }, []);

  useEffect(() => {
    if (location && location.pathname !== '/login' && !user) {
      const userData = localStorage.getItem('user');
      if (!userData) {
        localStorage.setItem('token', '');
        window.location.href = '/login';
      } else {
        const user = (JSON.parse(userData) as unknown) as User;
        setUser(user);
      }
    }
  }, [location, user]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            triggerBg: theme.leftSideMenuBgColor,
          },
          Menu: {
            darkSubMenuItemBg: '#001529',
          }
        },
        token: {
          colorPrimary: theme.colorPrimary,
        }
      }}
    >
      <AgentContext.Provider value={agent}>
        <AntdApp>
          <div className="App">
            <Suspense fallback={<Spin size='large' />}>
              <Routes>
                <Route path="/login" element={<Login onLogin={(user) => setUser(user)} />} />
                <Route path="*" element={
                  <PrivateRoute path="*">
                    <UserContext.Provider value={user}>
                      <Home />
                    </UserContext.Provider>
                  </PrivateRoute>
                } />
              </Routes>
            </Suspense>
          </div>
        </AntdApp>
      </AgentContext.Provider>
    </ConfigProvider>
  );
}

export default App;
