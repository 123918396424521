import { Button, Space, Spin, Table, Form, Modal, Input, Typography, Tooltip, theme, App } from 'antd';
import Search from 'antd/lib/input/Search';
import { DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FORM_LAYOUT_4_20, FORM_VALIDATE_MESSAGES } from '../../types/config';
import { createApiKey, deleteApiKey, getApiKeyForOne, getApiKeyList } from '../../services/ApiKeyService';
import { Apikey } from '../../types/Apikey';
import copy from 'copy-to-clipboard';
const { useToken } = theme;

const { TextArea } = Input;

type ApikeyTableProps = {};

const apikeyListSearchTextFilter = (record: Apikey, searchText: string): boolean => {
  if (searchText === '') return true;
  return record.name.toLowerCase().includes(searchText);
};

export const ApiKeyTable: FunctionComponent<ApikeyTableProps> = () => {
  const { token } = useToken();
  const { modal, message } = App.useApp();
  const [searchText, setSearchText] = useState<string>('');
  const [apikeyList, setApikeyList] = useState<Apikey[]>();
  const [isApiKeyModalVisible, setIsApiKeyModalVisible] = useState(false);

  const [form] = Form.useForm<{
    name: string;
    apikey: string;
  }>();


  useEffect(() => {
    reloadApiKeyList();
  }, []);

  const reloadApiKeyList = async () => {
    try {
      const apikeyList = await getApiKeyList();
      setApikeyList(apikeyList);
    } catch (e) {
      setApikeyList([]);
    }
  };

  const onSearch = (searchText: string) => {
    if (searchText) {
      setSearchText(searchText.toLowerCase());
    } else {
      setSearchText('');
    }
  };

  const handleApiKeyModalCancel = () => {
    setIsApiKeyModalVisible(false);
  };

  const handleGenerateClick = async () => {
    const apikey = await getApiKeyForOne();
    form.setFieldsValue({ apikey: apikey })
  }

  const handleApiKeyModalOk = async () => {
    try {
      await form.validateFields();
      const { name, apikey } = form.getFieldsValue();
      await createApiKey({
        name,
        apikey
      }).then(() => {
        setIsApiKeyModalVisible(false);
        reloadApiKeyList();
      }).catch((e) => {
        message.error(e.data.message)
      })
    } catch (e) { }
  };

  const columns = [
    { title: 'ID', dataIndex: '_id' },
    { title: 'Name', dataIndex: 'name' },
    {
      title: 'Api Key', dataIndex: 'apikey',
      render: (_text: string) => {
        return (
          <Space>
            <Tooltip placement="top" title={_text}><div style={{ maxWidth: 300, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{_text}</div></Tooltip>
            <CopyOutlined
              style={{ color: token.colorPrimary, cursor: 'pointer' }}
              onClick={() => {
                if (copy(_text)) {
                  message.success('Copy successfully!')
                } else {
                  message.warning('Copy failed!')
                }
              }}
            />
          </Space>
        )
      }
    },
    { title: 'Created User', dataIndex: 'userName' },
    { title: 'CreatedAt', dataIndex: 'createdAt' },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_text: string, record: Apikey) => (
        <Space size="middle">
          <DeleteOutlined
            style={{ color: token.colorError }}
            onClick={() => {
              modal.confirm({
                title: 'Confirm',
                content: `Are you sure to delete ${record.name}?`,
                okText: 'Yes',
                cancelText: 'Cancel',
                onOk: async () => {
                  await deleteApiKey(record._id);
                  reloadApiKeyList();
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  return apikeyList ? (
    <>
      <Table
        title={() => (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <b>Api Key</b>
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  form.resetFields();
                  setIsApiKeyModalVisible(true);
                }}
              >
                Create ApiKey
              </Button>
              <Search placeholder="Search by ApiKey name" allowClear onSearch={onSearch} style={{ width: 200 }} />
            </Space>
          </div>
        )}
        rowKey={(record) => record._id}
        columns={columns}
        dataSource={apikeyList.filter((item) => apikeyListSearchTextFilter(item, searchText))}
      />

      <Modal
        title='Create ApiKey'
        open={isApiKeyModalVisible}
        onCancel={handleApiKeyModalCancel}
        onOk={handleApiKeyModalOk}
      >
        <Form {...FORM_LAYOUT_4_20} name="add-user" form={form} validateMessages={FORM_VALIDATE_MESSAGES}>
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your ApiKey Name!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Api Key">
            <Space>
              <Form.Item
                name="apikey"
                noStyle
                rules={[{ required: true, message: 'Please input or generate your ApiKey!' }]}
              >
                <TextArea style={{ width: 325 }} autoSize={{ minRows: 3, maxRows: 6 }} />
              </Form.Item>
              <Tooltip title="Generate a new ApiKey">
                <Typography.Link style={{ color: token.colorPrimary }} href="javascript:void(0);" onClick={() => { handleGenerateClick() }}>generate</Typography.Link>
              </Tooltip>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  ) : (
    <div style={{ height: 312, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Spin />
    </div>
  );
};
