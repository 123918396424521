import { CloseCircleOutlined, InboxOutlined } from "@ant-design/icons";
import { Button, Modal, Result, Space, Typography, Upload, UploadFile, UploadProps, theme } from "antd"
import { RcFile } from "antd/es/upload";
import React, { useEffect, useState } from "react";
import { importDataWithQVD } from "../../services/GranuleService";
const { Dragger } = Upload;
const { useToken } = theme;

type StepsConfig = 'files' | 'results';

export type ImportGranulesModalProps = {
  open: boolean;
  kapsuleId: string;
  handleSubmit: () => void;
  handleCancel: () => void;
}

export const ImportGranulesModal: React.FC<ImportGranulesModalProps> = ({ open, kapsuleId, handleSubmit, handleCancel }) => {
  const [currStep, setCurrStep] = useState<StepsConfig>('files');
  const [errorMsg, setErrorMsg] = useState<string>();
  const [successMsg, setSuccessMsg] = useState<string>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const { token } = useToken();

  useEffect(() => {
    if (open) {
      setErrorMsg(undefined);
      setSuccessMsg(undefined);
      setFileList([]);
      setCurrStep('files');
    }
  }, [open]);

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file as RcFile);
    });
    setUploading(true);
    try {
      const rows = await importDataWithQVD(kapsuleId, formData);
      setSuccessMsg(`Total matched ${rows} rows of data.`);
    } catch (err) {
      setErrorMsg((err as Error).message);
    } finally {
      setUploading(false);
      setCurrStep('results');
    }
  }

  const props: UploadProps = {
    multiple: true,
    accept: '.qvd',
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((list) => [...list, file]);
      return false;
    },
    fileList,
  };

  return (
    <React.Fragment>
      <Modal
        width={750}
        title="Import granules from QVD"
        open={open}
        footer={null}
        maskClosable={false}
        onCancel={handleCancel}
      >
        {currStep === 'files' && (
          <React.Fragment>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag QVD to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other
                banned files.
              </p>
            </Dragger>
            <Space style={{ width: '100%', justifyContent: 'right' }}>
              <Button
                type="primary"
                onClick={handleUpload}
                disabled={fileList.length === 0}
                loading={uploading}
                style={{ marginTop: 16 }}
              >
                {uploading ? 'Uploading' : 'Start Upload'}
              </Button>
            </Space>
          </React.Fragment>
        )}
        {currStep === 'results' && (
          <React.Fragment>
            {errorMsg ? (
              <Result
                status="error"
                title="Import Failed"
              >
                <div className="desc">
                  <Typography.Paragraph>
                    <Typography.Text
                      strong
                      style={{
                        fontSize: 16,
                      }}
                    >
                      The content you submitted has the following error:
                    </Typography.Text>
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    <pre><CloseCircleOutlined style={{ color: token.colorError }} /> {errorMsg}</pre>
                  </Typography.Paragraph>
                </div>
              </Result>
            ) : (
              <Result
                status="success"
                title="Import Successed"
                subTitle={successMsg}
              ></Result>
            )}
            <Space style={{ width: '100%', justifyContent: 'right' }}>
              <Button
                type="primary"
                onClick={handleSubmit}
              >OK</Button>
            </Space>
          </React.Fragment>
        )}
      </Modal>
    </React.Fragment>
  )
}