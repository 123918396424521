import { Spin } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getGranules } from '../../services/GranuleService';
import { Granule } from '../../types/Granule';
import { GranulesSummary } from '../granulesSummary/GranulesSummary';
import { GranulesTable } from '../granulesTable/GranulesTable';

type GranulesProps = {};

type GranulesParams = {
  kapsuleId: string;
};

export const Granules: FunctionComponent<GranulesProps> = () => {
  const { kapsuleId } = useParams<keyof GranulesParams>() as GranulesParams;
  const [granules, setGranules] = useState<Granule[]>();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    reloadGranules(kapsuleId);
  }, [kapsuleId]);

  const reloadGranules = async (kapsuleId: string) => {
    try {
      setLoading(true);
      const granules = await getGranules(kapsuleId);
      setGranules(granules);
    } catch (e) {
      setGranules([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin size={'large'} spinning={loading}>
      <div style={{ background: 'rgba(255, 255, 255, 0.3)' }}>
        <GranulesSummary granules={granules} />
        <GranulesTable granules={granules} kapsuleId={kapsuleId} handleReloadGranules={() => reloadGranules(kapsuleId)} />
      </div>
    </Spin>
  );
};
