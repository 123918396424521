import axios from 'axios';
import { Log } from '../types/Log';
import { formatToLocale, stringToUTC } from '../utils/dateUtils';
import { KAPTAIN_URL } from './config';

export const getLogList = async (): Promise<Log[]> => {
  const response = await axios.get<Log[]>(`${KAPTAIN_URL}/api/log/list`);
  const raw = response.data;
  return raw.map((item) => {
    return {
      ...item,
      lastRunAt: item.lastRunAt && formatToLocale(stringToUTC(item.lastRunAt), 'yyyy-MM-dd HH:mm:ss'),
      lastFinishedAt: item.lastFinishedAt && formatToLocale(stringToUTC(item.lastFinishedAt), 'yyyy-MM-dd HH:mm:ss'),
      timeConsuming: (item.lastRunAt && item.lastFinishedAt) ? stringToUTC(item.lastFinishedAt) - stringToUTC(item.lastRunAt) : 0
    };
  });
};

export const downloadLogFile = async (logId: string): Promise<void> => {
  window.open(`${KAPTAIN_URL}/api/log/download/${logId}`);
};

export type SysLogDTO = {
  sys: string[];
  app: string[];
}
export const getSysLogList = async (): Promise<SysLogDTO> => {
  const response = await axios.get<SysLogDTO>(`${KAPTAIN_URL}/api/log/sys/list`);
  const raw = response.data;
  return raw;
};

export const getSysLogContent = async (type: keyof SysLogDTO, filename: string): Promise<string> => {
  const response = await axios.get<string>(`${KAPTAIN_URL}/api/log/sys/${type}/${filename}/content`);
  const raw = response.data;
  return raw;
};